import authAxios from "../../../common/authAxios";
import { errorNotification } from "../../../helpers/errorNotification";
import { successNotification } from "../../../helpers/successNotification";

export class PhysicalEducationCourseEnrollmentService {
    private static baseUrl = '/astanait-student-module/api/v1/pe';

    static getStudentPhysicalEducationChoicesBySetting() {
        const settingId = 2;
        return authAxios.get(`${this.baseUrl}/student-choose-by-setting`, {
            params: {
                settingId
            }
        })
            .then((response) => {
                // successNotification('Courses fetched successfully');
                return response.data;
            })
            .catch((error) => {
                errorNotification('Error fetching data', error.response?.status);
                console.error('Error:', error);
            });
    }

    static getPhysicalEducationChoicesByFilter(typeId: number, scheduleDay: number) {
        const settingId = 2;
        return authAxios.get(`${this.baseUrl}/choose-by-filter`, {
            params: { typeId, settingId, scheduleDay }
        })
            .then((response) => {
                // successNotification('Courses fetched successfully');
                return response.data;
            })
            .catch((error) => {
                errorNotification('Error fetching data', error.response?.status);
                console.error('Error:', error);
            });
    }

    static getPhysicalEducationTypes() {
        return authAxios.get(`${this.baseUrl}/types`)
            .then((response) => response.data)
            .catch((error) => {
                errorNotification('Error fetching physical education courses', error.response?.status);
                console.error('Error:', error);
            });
    }

    static createOrUpdateStudentChoose(studentId: number, chooseId: number) {
        return authAxios.post(`${this.baseUrl}/student-choose/create-or-update`, {
            studentId,
            chooseId
        })
            .then(response => {
                return response.data;
            })
            .catch(error => {
                const errorMessage = error.response?.data?.message || 'Error selecting course';
                errorNotification(errorMessage);
            });
    }

    static getStudentPhysicalEducationChooseSettingsById() {
        const settingId = 2;
        return authAxios.get(`${this.baseUrl}/student-choose/settings/${settingId}`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                errorNotification('Error fetching settings', error.response?.status);
                console.error('Error:', error);
            });
    }
}
