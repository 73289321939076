import Page from '../../../../ui/Page'
import RowSpan from '../../../../ui/RowSpan'
import { Alert, Button, Form, Input, Select, Space, Table } from 'antd';
import { DictionaryCatalogDtoResponse } from '../../../../interfaces/DictionaryCatalogDtoResponse';
import { ApplicationTypeDtoResponse } from '../../../../interfaces/Applications/response/ApplicationTypeDtoResponse';
import { ApplicationService } from '../../../../services/ApplicationService';
import useFetchData from '../../../../hooks/useFetchData';
import useApplications from '../useApplications';
import { ColumnsType } from 'antd/lib/table';
import { ApplicationDtoResponse } from '../../../../interfaces/Applications/response/ApplicationDtoResponse';
import ApplicationStatusTag from '../ApplicationStatusTag';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import AuthenticatedContent from '../../../../common/AuthenticatedContent';
import moment from 'moment';

interface StudentSearchFormProps {
    isAllApplications?: boolean;
    isOrderFormVisible?: boolean;
    isSignApplicationsPage?: boolean;
}

const StudentApplicationsListPage: React.FC<StudentSearchFormProps> = ({ isOrderFormVisible = false, isSignApplicationsPage = false, isAllApplications = false }) => {
    const { data: statuses } = useFetchData<DictionaryCatalogDtoResponse[]>(ApplicationService.getApplicationStatuses, true);
    const { data: types } = useFetchData<ApplicationTypeDtoResponse[]>(ApplicationService.getApplicationTypes, true);
    const { data, methods } = useApplications({ fetchAllApplications: false });
    const history = useHistory();

    const [form] = Form.useForm();

    const columns: ColumnsType<ApplicationDtoResponse> = [
        {
            "title": "№",
            "dataIndex": "key",
            "key": "key",
            render(value, record, index) {
                return index + 1;
            },
        },
        {
            "title": "IIN",
            "dataIndex": "iin",
            "key": "iin",
            render(value, record, index) {
                return record.student.iin;
            },
        },
        {
            "title": "Student name",
            "dataIndex": "studentName",
            "key": "studentName",
            render(value, record, index) {
                return record.student.fullName;
            },
        },
        {
            "title": "Group",
            "dataIndex": "group",
            "key": "group",
            render(value, record, index) {
                return record.student.group.title;
            },
        },
        {
            "title": "Course",
            "dataIndex": "course",
            "key": "course",
            render(value, record, index) {
                return record.student.courseGrade;
            },
        },
        {
            "title": "Financing type",
            "dataIndex": "financing",
            "key": "financing",
            render(value, record, index) {
                return record.student.financing.typeOfFinancing;
            },
        },
        {
            "title": "Degree",
            "dataIndex": "degree",
            "key": "degree",
            render(value, record, index) {
                return record.student.educationProgram.academicDegree.titleRu;
            },
            width: '10%'
        },
        {
            "title": "Created At",
            "dataIndex": "createdAt",
            "key": "createdAt",
            render(value, record, index) {
                return (record && record.createdAt ? moment(record.createdAt).format('DD.MM.YYYY HH:mm') : 'Не найдено');
            },
        },
        {
            "title": "Application type",
            "dataIndex": "typeId",
            "key": "typeId",
            render(value, record, index) {
                return record.typeId.nameEn;
            },
        },
        {
            "title": "Current step",
            "dataIndex": "currentStep",
            "key": "currentStep",
        },
        {
            "title": "Status",
            "dataIndex": "statusId",
            "key": "statusId",
            render(value, record, index) {
                return (<ApplicationStatusTag statusNameRu={record.statusId.nameRu} />);
            }
        },
        {
            "title": "Action",
            "dataIndex": "action",
            "key": "action",
            render(value, record, index) {
                return (<Button type="primary" htmlType="submit" onClick={() => window.open(`/application/${record.id}`, '_blank')}>View</Button>);
            }
        }
    ];
    useEffect(() => {
        if (isSignApplicationsPage) {
            methods.setSign(true);
        }
        if (isAllApplications) {
            methods.setAllApplications(true);
            methods.handleFilter('');
        }

    }, [isSignApplicationsPage, isAllApplications]);

    const onFinish = (values: any) => {
        methods.setIin(values.iin);
        methods.setFullname(values.fullname);
        if (isAllApplications) {
            methods.setStatusId(values.statusId);
            methods.setTypeId(values.typeId);
            methods.handleFilter(values);
        }
        if (isSignApplicationsPage) {
            methods.handleFilterBySign(values);
        }
        if (isOrderFormVisible) {
            methods.handleFilterByOrder(values);
        }
    };

    return (
        <Page title='Applications'
            subtitle='List of students applications'>
            <RowSpan left={(
                <Space direction='vertical'>
                    <Space>


                        {isSignApplicationsPage && (<></>)}


                        <Form form={form} onFinish={onFinish}>
                            {isAllApplications ? (
                                <>
                                    <Form.Item
                                        label="Status"
                                        name="statusId"
                                        style={{ width: 500 }}
                                    >
                                        <Select placeholder="Select application status" allowClear>
                                            {statuses?.map((status) => (
                                                <Select.Option key={status.id} value={status.id}>{`${status.nameRu} / ${status.name} / ${status.nameEn}`}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Application type"
                                        name="typeId"
                                        style={{ width: 500 }}
                                    >
                                        <Select placeholder="Select application type" allowClear>
                                            {types?.map((status) => (
                                                <Select.Option key={status.id} value={status.id}>{`${status.nameRu} / ${status.name} / ${status.nameEn}`}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </>
                            ) : isOrderFormVisible && (
                                <Form.Item
                                    label="Order"
                                    name="orderCreated"
                                    style={{ width: 500 }}
                                    rules={[{ required: true }]}
                                >
                                    <Select placeholder="Select application order" >
                                        <Select.Option key={1} value={1}>Приказ создан</Select.Option>
                                        <Select.Option key={0} value={0}>Приказ не создан</Select.Option>
                                    </Select>
                                </Form.Item>)}
                            <Form.Item
                                label="Student's IIN"
                                name='iin'
                                style={{ width: 500 }}>
                                <Input
                                    placeholder="Enter student's IIN"
                                    value={data.iin}
                                    onChange={(e) => methods.setIin(e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Student's full name"
                                name='fullname'
                                style={{ width: 500 }}>
                                <Input
                                    placeholder="Enter student's full name"
                                    value={data.fullname}
                                    onChange={(e) => methods.setFullname(e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Space>
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                                        Find
                                    </Button>
                                    {/* <Button type="primary" icon={<SearchOutlined />} onClick={methods.handleFilterBySign}>
                                            Applications for signature
                                        </Button> */}
                                    {/* <AuthenticatedContent type='addRole' role={"STUDENT_DEPARTMENT"}>
                                            <Button onClick={() => setOrderFormVisible(!isOrderFormVisible)}>
                                                {isOrderFormVisible ? 'Switch to Status/Type Filter' : 'Switch to Order Filter'}
                                            </Button>
                                        </AuthenticatedContent> */}
                                </Space>
                            </Form.Item>
                        </Form>

                    </Space>
                    <br />
                </Space>
            )} />


            {data.applicationsData && data.applicationsData.length > 0 && (
                <Table
                    pagination={{
                        current: data.currentPage,
                        total: data.totalPages * 10,
                        pageSize: 10,
                        onChange: (page) => methods.setCurrentPage(page),
                    }}
                    columns={columns}
                    loading={data.isLoading}
                    dataSource={data.applicationsData}
                    rowKey={(record) => record.id.toString()}
                // onRow={(record) => {
                //     return {
                //         onClick: () => {
                //             history.push(`/application/${record.id}`);
                //         },
                //         style: { cursor: 'pointer' },
                //     };
                // }}
                />
            )}
        </Page>
    )
}

export default StudentApplicationsListPage