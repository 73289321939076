/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Popconfirm, notification } from 'antd';
import { Tag } from 'antd';
import { AppointmentDatesDtoResponse } from '../../../interfaces/AppointmentDatesDtoResponse';
import { PsychologistService } from '../../../services/PsychologistService';
import './appointment.css';
import UserService from '../../../services/userService';
import { errorNotification } from '../../../helpers/errorNotification';

const usePsychologistAppointmentPage = () => {
  const [dates, setDates] = useState<AppointmentDatesDtoResponse[]>([]);
  const [appointment, setAppointment] = useState<AppointmentDatesDtoResponse[]>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const confirm = (value1: any, value: any) => {
    PsychologistService.createAppointment(value1 + ' ' + value + ':00')
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'You successfully made an appointment!',
          duration: 3.5,
        });
        fetchData();
      })
      .catch((err) => errorNotification(err.response?.data.message, err.response?.status))
  };
  const deleteAppointment = (value: any) => {
    PsychologistService.deleteAppointment(value)
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'You successfully made an appointment!',
          duration: 3.5,
        });
        fetchData();
      })
  };

  const fetchData = () => {
    PsychologistService.getAllAppointments()
      .then((response) => {
        setDates(response.data);
      })

    PsychologistService.getAppointmentByUserId(UserService.getCurrentUser().id).then(({ data }) => {
      setAppointment(data);
    });

    // UserService.getProfileByPrincipal().then(({ data }) => {
    //   PsychologistService.getAppointmentByUserId(data.student.userId).then(({ data }) => {
    //     setAppointment(data);
    //   });
    // });
  };

  const userAppointmentsColumns = [
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
    },

    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render: (_: any, record: any) => (
        <>
          <Tag color={'green'}>{record.time.toUpperCase()}</Tag>
        </>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (_: any, record: any) => (
        <>
          <Tag color={'green'}>{record.date.toUpperCase()}</Tag>
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record: any) => (
        <>
          {record.status === "Canceled" &&
            <p className="active">{record.status}</p>
          }
          {record.status === "Сreated" &&
            <p className="create"> Waiting for confirmation </p>
          }
          {record.status === "Accepted" &&
            <p className="accept">{record.status}</p>
          }

        </>
      ),
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
      render: (_: any, record: any) => (
        <>
          <p color={'green'}>{record.comment}</p>
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'delete',
      key: 'delete',
      render: (_: any, record: any) => (
        <>
          {
            <Popconfirm
              placement="topLeft"
              title={'Отменить запись?'}
              onConfirm={() => deleteAppointment(record.id)}
              okText="Yes"
              cancelText="No" >
              <a>Cancel</a>
            </Popconfirm>
          }
        </>
      ),
    },
  ];

  const appointmentsColumns = [
    {
      dataIndex: 'weekDay',
      key: 'weekDay',
    },
    {
      dataIndex: 'date',
      key: 'date',
    },
    {
      dataIndex: 'firstTime',
      key: 'firstTime',
      render: (_: any, record: any) => (
        <>
          {
            <p>
              {record.firstTime}
              <br></br>
              {record.firstStatus === 'Available' ? (
                <Popconfirm
                  placement="topLeft"
                  title={'Confirm an appointment?'}
                  onConfirm={() => confirm(record.date, record.firstTime)}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>{record.firstStatus}</a>
                </Popconfirm>
              ) : (
                <p className="notactive">{record.firstStatus}</p>
              )}
            </p>
          }
        </>
      ),
    },
    {
      dataIndex: 'secondTime',
      key: 'secondTime',
      render: (_: any, record: any) => (
        <>
          {
            <p>
              {record.secondTime}
              <br></br>
              {record.secondStatus === 'Available' ? (
                <Popconfirm
                  placement="topLeft"
                  title={'Подтвердить запись?'}
                  onConfirm={() => confirm(record.date, record.secondTime)}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>{record.secondStatus}</a>
                </Popconfirm>
              ) : (
                <p className="notactive">{record.secondStatus}</p>
              )}
            </p>
          }
        </>
      ),
    },
    {
      dataIndex: 'thirdTime',
      key: 'thirdTime',
      render: (_: any, record: any) => (
        <>
          {
            <p>
              {record.thirdTime}
              <br></br>
              {record.thirdStatus === 'Available' ? (
                <Popconfirm
                  placement="topLeft"
                  title={'Подтвердить запись?'}
                  onConfirm={() => confirm(record.date, record.thirdTime)}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>{record.thirdStatus}</a>
                </Popconfirm>
              ) : (
                <p className="notactive">{record.thirdStatus}</p>
              )}
            </p>
          }
        </>
      ),
    },
  ];

  const filteredUserAppointments = appointment.map((appointment) => ({
    id: appointment.id,
    appointment: new Date(appointment.dateTime).toISOString().replace(/T.*/, '') + ' ' + new Date(appointment.dateTime).toLocaleTimeString(),
    student: appointment.studentFIO,
    group: appointment.group,
    comment: appointment.comment,
    status: appointment.status.statusTitle,
    time: new Date(appointment.dateTime).toLocaleTimeString('en', {
      timeStyle: 'short',
    }),
    date: new Date(appointment.dateTime).toLocaleDateString(),

  }));

  const filteredAppointmentDates = dates.map((date) =>
    date.weekDay === 'MONDAY'
      ? {
        key: dates,
        weekDay: date.weekDay,
        date: date.date,
        firstTime: date.times[0].time,
        secondTime: date.times[1].time,
        thirdTime: date.times[2].time,
        firstStatus: date.times[0].status,
        secondStatus: date.times[1].status,
        thirdStatus: date.times[2].status,
        firstAppointmentStatus: date.times[0].status,
        secondAppointmentStatus: date.times[1].status,
        thirdAppointmentStatus: date.times[2].status,

      }
      : {
        key: dates,
        weekDay: date.weekDay,
        date: date.date,
        firstTime: date.times[0].time,
        secondTime: date.times[1].time,
        firstStatus: date.times[0].status,
        secondStatus: date.times[1].status,
        firstAppointmentStatus: date.times[0].status,
        secondAppointmentStatus: date.times[1].status,
      }
  );

  return {
    columns: {
      userAppointmentsColumns,
      appointmentsColumns,
    },
    data: { filteredUserAppointments, filteredAppointmentDates },
  };
};

export default usePsychologistAppointmentPage;
