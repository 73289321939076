import { useEffect, useState } from 'react';
import Title from 'antd/es/typography/Title';
import { Button, Form, Modal, Space, Typography } from 'antd';
import PageWrapper from '../../../ui/PageWrapper';
import { Table } from 'antd';
import { AppointmentDatesDtoResponse } from '../../../interfaces/AppointmentDatesDtoResponse';
import { PsychologistService } from '../../../services/PsychologistService';
import './appointment.css';
import { Link } from 'react-router-dom';
import { notification } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useForm } from 'antd/lib/form/Form';

const PsychologicalAppointmentPage = () => {
  const [appointments, setAppointments] = useState<AppointmentDatesDtoResponse[]>([]);
  const [addModal, setAddModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [rejectModalValue, setRejectModalValue] = useState<any>();
  const [form] = useForm();

  const getAppointment = () => {
    PsychologistService.getAppointment()
      .then((response) => setAppointments(response.data)
      );
  }

  useEffect(() => {
    getAppointment()
  }, []);

  const changeDateStatus = (value: any, status: string) => {

    if (status == 'Not available') {
      PsychologistService.MakeDateUnavailable(value)
        .then(() => {
          notification.success({
            message: 'Success',
            description: 'You successfully change a date status!',
            duration: 3.5,
          });
          fetchData();
        });
    }
    else {
      PsychologistService.MakeDateAvailable(value)
        .then(() => {
          notification.success({
            message: 'Success',
            description: 'You successfully change a date status!',
            duration: 3.5,
          });
          fetchData();
        });
    }

  };
  const cancelAppointment = (status_id: any, comment: any) => {
    PsychologistService.updateAppointment(rejectModalValue, status_id, comment)
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'You successfully change an appointment status!',
          duration: 3.5,
        });
        setAddModal(false)
        fetchData();
      });
  };
  const handleRejectModalAdd = (record: any) => {
    setRejectModalValue(record);
    setAddModal(true);
  };
  const handleRejectModalShow = (record: any) => {
    setRejectModalValue(record);
    setShowModal(true);
  };
  const acceptAppointment = (id: any, status_id: any, comment: any) => {
    PsychologistService.updateAppointment(id, status_id, comment)
      .then(() => {
        notification.success({
          message: 'Success',
          description: 'You successfully change an appointment status!',
          duration: 3.5,
        });
        setAddModal(false)
        fetchData();
      });
  };
  const fetchData = () => {
    getAppointment()
  }

  const columns = [
    {
      title: 'Student',
      dataIndex: 'student',
      key: 'student',
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },

    {
      title: 'Status',
      key: 'status',
      render: (_: any, record: any) => (

        <Space size="middle">
          {record.status === "Available" &&

            <Link to={{
              pathname: '/add-appointment-page',
              state: { date: record.date, weekDay: record.weekDay, time: record.time }
            }}>
              <a>Add appointment </a>
            </Link>
          }

          {record.appointment_status === 1 &&
            <Space size="middle" className="button">  <Button
              type="primary"
              onClick={() => acceptAppointment(record.appointment_id, 2, "")}
              className="button"
            >
              Accept
            </Button>
              <Button
                onClick={() => handleRejectModalAdd(record.appointment_id)}
              >
                Cancel
              </Button></Space>}

          {record.status === "Not available" &&
            <p className="active">Not available</p>
          }
          {record.appointment_status === 2 &&
            <p className="accept">Accepted</p>
          }
          {record.appointment_status === 6 &&
            <p className="active">Canceled</p>
          }

        </Space>
      ),
    },
    {
      title: 'Reason',
      render: (record: any) => (
        <Button
          disabled={record.appointment_status !== 6}
          onClick={() => handleRejectModalShow(record.comment)}
        >
          Show reason
        </Button>
      ),
    },
  ];
  const checkIfAllTimesUnavailable = (times: any) => {
    return times.every((time: any) => time.status === 'Not available');
  };
  return (
    <PageWrapper>
      <section>
        <header >
          <Title level={1}>Appointment with a psychologist</Title>
        </header>
        {appointments.map((appointment, index) => (
          <section>
            <section>
              <Title level={5}>{appointment.weekDay + " " + appointment.date}</Title>

              {appointment.weekDay !== 'Cancelled' && (
                checkIfAllTimesUnavailable(appointment.times) ? (
                  <Button
                    type="primary"
                    onClick={() => changeDateStatus(appointment.date + " " + '10:00:00', 'Available')}
                  >
                    Make the date available
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    danger
                    onClick={() => changeDateStatus(appointment.date + " " + '10:00:00', 'Not available')}
                  >
                    Make the date unavailable
                  </Button>
                )
              )}
            </section>
            <br />

            <Modal
              title="Rejection reason"
              open={addModal} onCancel={() => setAddModal(false)}
              footer={[
                <Button key="back" onClick={() => setAddModal(false)}>
                  Cancel
                </Button>

              ]}
            >
              <Typography>
                Please write the reason for refusal
              </Typography>
              <Form onFinish={() => cancelAppointment(6, form.getFieldValue(("reason")))} form={form} >
                <Form.Item name="reason" required={true}>
                  <TextArea style={{ height: "200px" }} />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Modal>

            <Modal open={showModal} onCancel={() => setShowModal(false)} onOk={() => setShowModal(false)}>
              <Space direction='vertical' style={{ width: "100%" }}>
                <Title level={5}>Rejection reason</Title>
                <TextArea readOnly value={rejectModalValue} />

              </Space>
            </Modal>

            <Table
              columns={columns} size="small" scroll={{ x: 1000 }} dataSource={appointments[index].times.map((time) => (
                time.appointment === null ?
                  {
                    appointment: time.appointment,
                    student: "",
                    group: "",
                    email: "",
                    time: time.time,
                    date: appointment.date,
                    weekDay: appointment.weekDay,
                    status: time.status
                  } : {

                    appointment: time.appointment,
                    student: time.appointment.studentFIO,
                    group: time.appointment.group,
                    email: time.appointment.email,
                    time: time.time,
                    status: time.status,
                    appointment_status: time.appointment.status.id,
                    appointment_id: time.appointment.id,
                    comment: time.appointment.comment,
                  }
              ))}
            />
          </section>
        ))}
      </section>
    </PageWrapper>
  );
};

export default PsychologicalAppointmentPage;


