import authAxios from '../common/authAxios';
import { AppointmentDatesDtoResponse } from '../interfaces/AppointmentDatesDtoResponse';

export class PsychologistService {

  static getAllAppointments() {
    return authAxios.get<AppointmentDatesDtoResponse[]>(`/astanait-office-module/api/v1/psychologist/appointment/get-week`);
  }
  static createAppointment(AppointmentTime: any) {
    return authAxios.post(`/astanait-office-module/api/v1/psychologist/appointment/make-appointment?date_time=${AppointmentTime}`);
  }
  static deleteAppointment(id: any) {
    return authAxios.delete(`/astanait-office-module/api/v1/psychologist/appointment/delete-appointment/${id}`);
  }
  static addAppointment(AppointmentTime: any, StudentId: any) {
    return authAxios.post(`/astanait-office-module/api/v1/psychologist/appointment/make-appointment-by-psychologist?student_id=${StudentId}&date_time=${AppointmentTime}`);
  }
  static getAppointment() {

    return authAxios.get(`/astanait-office-module/api/v1/psychologist/appointment/get-all-appointments-with-week`);

  }
  static getAppointmentByUserId(userid: any) {

    return authAxios.get(`/astanait-office-module/api/v1/psychologist/appointment/get-appointment-by-userid?userId=${userid}`);
  }

  static MakeDateUnavailable(date: any) {

    return authAxios.post(`/astanait-office-module/api/v1/psychologist/appointment/make-appointment-date-unavailable?date_time=${date}`);

  }
  static MakeDateAvailable(date: any) {

    return authAxios.delete(`/astanait-office-module/api/v1/psychologist/appointment/make-appointment-date-available?date_time=${date}`);

  }
  static updateAppointment(id: any, status_id: any, comment: any) {

    return authAxios.put(`/astanait-office-module/api/v1/psychologist/appointment/update-appointment?appointment_id=${id}&appointment_status_id=${status_id}&comment=${comment}`);

  }



}