import Page from '../../../../ui/Page'
import RowSpan from '../../../../ui/RowSpan'
import { Alert, Button, Form, Input, Select, Space, Table } from 'antd';
import { DictionaryCatalogDtoResponse } from '../../../../interfaces/DictionaryCatalogDtoResponse';
import { ApplicationTypeDtoResponse } from '../../../../interfaces/Applications/response/ApplicationTypeDtoResponse';
import { ApplicationService } from '../../../../services/ApplicationService';
import useFetchData from '../../../../hooks/useFetchData';
import useApplications from '../useApplications';
import { ColumnsType } from 'antd/lib/table';
import { ApplicationDtoResponse } from '../../../../interfaces/Applications/response/ApplicationDtoResponse';
import ApplicationStatusTag from '../ApplicationStatusTag';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import AuthenticatedContent from '../../../../common/AuthenticatedContent';
import moment from 'moment';
import React from 'react';
import { PrivilegeDtoResponse } from '../../../../interfaces/Student/PrivilegeDtoResponse';

const StudentApplicationsListPage = () => {

    const history = useHistory();
    const [applicationsData, setApplicationsData] = React.useState<ApplicationDtoResponse[]>([]);
    const { data: statuses } = useFetchData<DictionaryCatalogDtoResponse[]>(ApplicationService.getApplicationStatuses, true);
    const { data: privileges } = useFetchData<PrivilegeDtoResponse[]>(ApplicationService.getPrivilege, true);
    const { data, methods } = useApplications({ fetchAllApplications: false });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [gender, setGender] = useState(Number);
    const [statusId, setStatusId] = useState(null);
    const [step, setStep] = useState(null);
    const [privilege, setPrivilege] = useState(null);
    const [iin, setIin] = useState('');
    const [fullname, setFullname] = useState('');
    const [form] = Form.useForm();

    const columns: ColumnsType<ApplicationDtoResponse> = [
        {
            "title": "№",
            "dataIndex": "key",
            "key": "key",
            render(value, record, index) {
                return index + 1;
            },
        },
        {
            "title": "IIN",
            "dataIndex": "iin",
            "key": "iin",
            render(value, record, index) {
                return record.student.iin;
            },
        },
        {
            "title": "Student name",
            "dataIndex": "studentName",
            "key": "studentName",
            render(value, record, index) {
                return record.student.fullName;
            },
        },
        {
            "title": "Group",
            "dataIndex": "group",
            "key": "group",
            render(value, record, index) {
                return record.student.group.title;
            },
        },
        {
            "title": "Course",
            "dataIndex": "course",
            "key": "course",
            render(value, record, index) {
                return record.student.courseGrade;
            },
        },
        {
            "title": "Financing type",
            "dataIndex": "financing",
            "key": "financing",
            render(value, record, index) {
                return record.student.financing.typeOfFinancing;
            },
        },
        {
            "title": "Degree",
            "dataIndex": "degree",
            "key": "degree",
            render(value, record, index) {
                return record.student.educationProgram.academicDegree.titleRu;
            },
            width: '10%'
        },
        {
            "title": "Created At",
            "dataIndex": "createdAt",
            "key": "createdAt",
            render(value, record, index) {
                return (record && record.createdAt ? moment(record.createdAt).format('DD.MM.YYYY HH:mm') : 'Не найдено');
            },
        },
        {
            "title": "Application type",
            "dataIndex": "typeId",
            "key": "typeId",
            render(value, record, index) {
                return record.typeId.nameEn;
            },
        },
        {
            "title": "Current step",
            "dataIndex": "currentStep",
            "key": "currentStep",
        },
        {
            "title": "Status",
            "dataIndex": "statusId",
            "key": "statusId",
            render(value, record, index) {
                return (<ApplicationStatusTag statusNameRu={record.statusId.nameRu} />);
            }
        },
        {
            "title": "Action",
            "dataIndex": "action",
            "key": "action",
            render(value, record, index) {
                return (<Button type="primary" htmlType="submit" onClick={() => window.open(`/application/${record.id}`, '_blank')} >View</Button>);
            }
        }
    ];
    useEffect(() => {

        const zeroBasedPage = currentPage - 1;
        ApplicationService.getApplicationsForDorm({
            gender: gender,
            statusId: statusId,
            step: step,
            privilege: privilege,
            iin: iin,
            fullname: fullname,
        }, zeroBasedPage).then((res) => {
            setApplicationsData(res.data.data)
            setTotalPages(res.data.TotalPages);
            console.log(applicationsData)
        }).finally(() => { });

    }, [gender, statusId, step, privilege, iin, fullname]);

    const onFinish = (values: any) => {
        setGender(values.gender);
        setStatusId(values.statusId);
        setStep(values.step);
        setPrivilege(values.privilege);
        setIin(values.iin);
        setFullname(values.fullname);
    };

    return (
        <Page title='Applications for dorm'
            subtitle='List of students applications'>
            <RowSpan left={(
                <Space direction='vertical'>
                    <Space>

                        <Form form={form} onFinish={onFinish}>

                            <Form.Item
                                label="Gender"
                                name="gender"
                                style={{ width: 500 }}
                            >
                                <Select placeholder="Select gender" allowClear>
                                    <Select.Option key={2} value={2}>Male</Select.Option>
                                    <Select.Option key={1} value={1}>Female</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Status"
                                name="statusId"
                                style={{ width: 500 }}
                            >
                                <Select placeholder="Select application status" allowClear>
                                    {statuses?.map((status) => (
                                        <Select.Option key={status.id} value={status.id}>{`${status.nameRu} / ${status.name} / ${status.nameEn}`}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Step"
                                name="step"
                                style={{ width: 500 }}
                            >
                                <Select placeholder="Select application step" >
                                    <Select.Option key={1} value={1}>1</Select.Option>
                                    <Select.Option key={2} value={2}>2</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Privilege"
                                name="privilege"
                                style={{ width: 500 }}
                            >
                                <Select placeholder="Select privilege" allowClear>
                                    {privileges?.map((privilege) => (
                                        <Select.Option key={privilege.id} value={privilege.id}>{`${privilege.nameRu}`}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Student's IIN"
                                name='iin'
                                style={{ width: 500 }}>
                                <Input
                                    placeholder="Enter student's IIN"
                                    value={data.iin}
                                    onChange={(e) => methods.setIin(e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Student's full name"
                                name='fullname'
                                style={{ width: 500 }}>
                                <Input
                                    placeholder="Enter student's full name"
                                    value={data.fullname}
                                    onChange={(e) => methods.setFullname(e.target.value)}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Space>
                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                                        Find
                                    </Button>

                                </Space>
                            </Form.Item>
                        </Form>

                    </Space>
                    <br />
                </Space>
            )} />


            <Table
                pagination={{
                    current: currentPage,
                    total: totalPages * 10,
                    pageSize: 10,
                    onChange: (page) => setCurrentPage(page),
                }}
                columns={columns}
                dataSource={applicationsData}
                rowKey={(record) => record.id.toString()}
            // onRow={(record) => {
            //     return {
            //         onClick: () => {
            //             history.push(`/application/${record.id}`);
            //         },
            //         style: { cursor: 'pointer' },
            //     };
            // }}
            />
        </Page>
    )
}

export default StudentApplicationsListPage